import { signal } from '@preact/signals'
import { useQuery } from 'preact-fetching'

interface Item {
  name: string
  link: string
  avatar: string
  descr: string
}

export function Counter() {
  const count = signal(0)

  const { isLoading, isError, error, data } = useQuery('', async () => {
    const resp = await fetch(
      `https://raw.githubusercontent.com/rnmeow/blog/master/links.json`,
    )
    const data = await resp.json()

    return data as Item[]
  })

  if (isError) {
    throw `An error occurred! ${error.message}`
  }

  if (!isLoading) {
    return (
      <>
        <button type="button" onClick={() => count.value++}>
          Counter {count}
        </button>
        <div>{JSON.stringify(data)}</div>
      </>
    )
  }

  return <div>Loading...</div>
}
